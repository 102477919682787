import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/customer_experience.jpg'
import person from '../../images/person.png'

const pageTitle = "Customer Experience and Design";
const pageSlug = "customer-experience-and-design";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="digital-consulting-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">To deliver a quality customer experience, you must put the customer 
            at the center of everything you do.</h6>
        </div>
      </section>

      <section id="p_digital_solutions" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    At Nuerex we’re researchers, designers, strategists and IT professionals, who have 
                    chosen a philosophy that unifies traditional consulting and digital agency services. 
                    Resulting in a meaningful, modern, and measurable outcome.
                    </h3>
                   <p>This transformative approach allows us to craft human-centered experiences and solutions 
                       that are powered by innovation strategies, technologies, and tactics that will drive your 
                       organizations to leap forward.</p>
                    <span className="break"></span>
                    <h6>How can we help you?</h6>
                    <p>Together we can create exceptional digital experiences for your customers.</p>
                    <Link to="/contact-us" className="button green">Contact us</Link>
                    <span className="break"></span>
                    <h4>Customer Experience and Design Services</h4>
                    <p>Our Customer Experience and Design team are always ready to help improve your 
                        experience by offering the following:</p>
                    <h5>Strategy & Business Design</h5>
                    <p>Our experts help our clients understand their customer’s frustrations and complaints so 
                        that we can define their experiences. We strive to solve both the customer problem and 
                        intricate business problem.</p>
                    <ul>
                        <li>Research and Insights</li>
                        <li>Journey Mapping </li>
                        <li>Roadmap Planning </li>
                        <li>Product and Service Design</li>
                        <li>Organization Design</li>
                    </ul>
                    <h5>Customer Innovation</h5>
                    <p>We strive to help our clients decide on the best way to accelerate innovation and 
                        validate innovative ideas with real customers, by offering:</p>
                    <ul>
                        <li>Prototyping</li>
                        <li>Innovation Workshops</li>
                        <li>Design Sprints</li>
                    </ul>
                    <span className="break"></span>
                    <h3 className="intext">Business is never isolated!</h3>
                    <span className="break"></span>
                    <p>Constant digital and technological innovations are empowering business innovation and 
                        transformation, growing and evolving require adapting to new services or designing your 
                        own. With our expertise and experience, we can help you do both.</p>
                    <span className="break"></span>
                    <Link to="/technology-services" className="simple-link">DISCOVER OUR TECHNOLOGY SOLUTIONS</Link>
                    <Link to="/business-consulting" className="simple-link">DISCOVER OUR BUSINESS CONSULTING SERVICES</Link>
                </div>
             {/*   <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default BusinessConsulting
